import axios from 'axios';
import qs from 'qs';

import { createHttpAgent, createHttpsAgent } from '@/utils/createHttpAgents';

export default class PureHttpClient {
  constructor({ baseURL = '', withCredentials = false } = {}) {
    this.http = null;
    this.baseURL = baseURL || '';
    this.withCredentials = withCredentials;
  }

  /**
   * Возвращает инстанс Axios
   * @return {AxiosInstance}
   */
  mainHttp() {
    this.http = axios.create({
      baseURL: this.baseURL,
      withCredentials: this.withCredentials,
      httpAgent: createHttpAgent(),
      httpsAgent: createHttpsAgent(),
    });
    return this.http;
  }

  /**
   * Get запрос
   * @param {string} path - путь запроса
   * @param {Object.<string, any>} payload - данные для запроса
   * @param {Object} options
   * @param {boolean} [options.isFormData=false] - нужно ли дополнительно сериализовать payload
   * @return {Promise<AxiosResponse<any>>}
   */
  get(path, payload = {}, { isFormData = false } = {}) {
    const request = {
      method: 'GET',
      url: path,
      params: { ...payload },
    };

    if (isFormData) {
      request.paramsSerializer = data => qs.stringify(data, { skipNulls: true });
    }

    return this.mainHttp().request(request);
  }

  /**
   * POST запрос
   * @param {string} path - путь запроса
   * @param {Object.<any, any>} payload - данные для запроса
   * @param {Object} [options]
   * @param {boolean} [options.isFormData=false] - нужно ли дополнительно сериализовать данные запроса
   * @param {Object.<string, any>} [options.payloadParams={}] - параметры URL, которые будут отправлены с запросом
   * @return {Promise<AxiosResponse<any>>}
   */
  post(path, payload = {}, { isFormData = false, payloadParams = {} } = {}) {
    const request = {
      method: 'POST',
      url: path,
      data: payload,
      params: { ...payloadParams },
    };

    if (isFormData) {
      request.transformRequest = data => qs.stringify(data, { skipNulls: true });
    }

    return this.mainHttp().request(request);
  }
}
