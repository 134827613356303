/// <reference path="./typedefs.js" />

/**
 * @param {Object<string, any>} data
 * @param {string} fullValue
 * @return {FormattedElement}
 */
export const formattedElement = (data, fullValue) => {
  const dataStreet = data.street ? data.street : '';
  const street = data.street_with_type || dataStreet;
  const streetSplitSymbol = data.settlement_with_type && street ? ', ' : '';

  return {
    areaWithType: data?.area_with_type || null,
    block: data?.block || null,
    blockDefault: data?.block || null,
    blockType: data?.block_type || null,
    cityFiasId: data?.city_fias_id || null,
    cityWithType: data?.city_with_type || null,
    fiasLevel: data?.fias_level || null,
    qcGeo: data?.qc_geo || null,
    fullValue,
    geoLat: data.geo_lat || null,
    geoLon: data.geo_lon || null,
    house: data?.house || null,
    houseDefault: data?.house || null,
    houseType: data?.house_type || null,
    kladrId: data?.kladr_id || null,
    postalCode: data?.postal_code || null,
    regionWithType: data?.region_with_type || null,
    settlementFiasId: data?.settlement_fias_id || null,
    settlementWithType: data.settlement_with_type || null,
    street: data.city && data.settlement_with_type
      ? data.settlement_with_type + streetSplitSymbol + street
      : street,
    streetFiasId: data?.street_fias_id || null,
    streetWithType: data?.street_with_type || null,
  };
};
