import { createInjectionState } from '@vueuse/shared';
import { captureException } from '@sentry/browser';
import {
  computed,
  reactive,
  toRefs,
  useContext,
  useStore,
  watch,
} from '@nuxtjs/composition-api';

import useHttpClient from '@/compositions/useHttpClient';
import useCommonModal from '@/compositions/useCommonModal';

import SuggestionsService from '@/services/dadata/SuggestionsService';

import {
  ISO_CODE_RUSSIA,
  ISO_CODE_UKRAINE,
  MAIN_REGIONS,
} from '@/utils/constants/globalConst';
import getOptionsSetCookie from '@/utils/getOptionsSetCookie';

const [useLocationProvider, useLocationStore] = createInjectionState(() => {
  const { $config, $cookies } = useContext();
  const { dispatch } = useStore();
  const $httpClient = useHttpClient();
  const { modal } = useCommonModal();

  const dadataSuggestionsService = new SuggestionsService();

  const state = reactive({
    detectingCity: false,
    detectError: false,
    isLocationPopup: false,
    cityName: $cookies.get('BITRIX_SM_location_name') || MAIN_REGIONS.msk.name,
    cityLocation: $cookies.get('BITRIX_SM_location_code') || null,
    cityCoords: $cookies.get('BITRIX_SM_location_coords') || [],
    countryCode: $cookies.get('BITRIX_SM_location_country') || ISO_CODE_RUSSIA,
    locationRegionCode: $cookies.get('BITRIX_SM_location_region_code') || null,
    popularList: [],
    closeLocation: {},
  });

  const isMoscow = computed(() => state.cityLocation === MAIN_REGIONS.msk.code);

  const isMoscowRegion = computed(() => MAIN_REGIONS.msk.regionFiasList.includes(state.locationRegionCode));

  const isSpb = computed(() => state.cityLocation === MAIN_REGIONS.spb.code);

  const isSpbRegion = computed(() => MAIN_REGIONS.spb.regionFiasList.includes(state.locationRegionCode));

  const isSpbOrMoscow = computed(() => isMoscow.value || isSpb.value);

  const isRussian = computed(() => state.countryCode === ISO_CODE_RUSSIA);

  const isUkraine = computed(() => state.countryCode === ISO_CODE_UKRAINE);

  /**
   * Возвращает название населенного пункта без типа субъекта
   * @param {string} [cityName='']
   */
  const removeExtraSymbol = (cityName = '') => cityName.replace(/ г$/i, '');

  /**
   * Устанавливаем название населенного пункта
   * @param {string} cityName
   */
  const setCityName = (cityName) => {
    const name = cityName || '';
    state.cityName = removeExtraSymbol(name);
    $cookies.set('BITRIX_SM_location_name', name, getOptionsSetCookie($config.domainCookie));
  };

  /**
   * Устанавливаем координаты населенного пункта
   * @param {array} cityCoords
   */
  const setCityCoords = (cityCoords) => {
    const coords = cityCoords || [];
    state.cityCoords = coords;
    $cookies.set('BITRIX_SM_location_coords', coords, getOptionsSetCookie($config.domainCookie));
  };

  /**
   * Устанавливаем ФИАС код населенного пункта
   * @param {string} location
   */
  const setCityLocation = (location) => {
    const locationCode = location || '';
    state.cityLocation = locationCode;
    $cookies.set('BITRIX_SM_location_code', locationCode, getOptionsSetCookie($config.domainCookie));
    dispatch('setLocation', location);
  };

  /**
   * Устанавливаем ISO код страны
   * @param {string} countryCode
   */
  const setCountryCode = (countryCode) => {
    const code = countryCode || '';
    state.countryCode = code;
    $cookies.set('BITRIX_SM_location_country', code, getOptionsSetCookie($config.domainCookie));
  };

  /**
   * Устанавливаем ФИАС код региона
   * @param {string} locationRegionCode
   */
  const setLocationRegionCode = (locationRegionCode) => {
    const code = locationRegionCode || '';
    state.locationRegionCode = code;
    $cookies.set('BITRIX_SM_location_region_code', code, getOptionsSetCookie($config.domainCookie));
  };

  /**
   * Обновляем данные населенного пункта из дадаты
   * @param {string} query
   * @param {string} location
   * @return {Promise<void>}
   */
  const fetchDadataCity = async (query, location) => {
    await dadataSuggestionsService.getInfoAboutCity({
      query,
      location,
    })
      .then((data) => {
        setCityCoords(data?.coords);
      })
      .catch((error) => {
        setCityName('');
        setCityCoords([]);
        setCityLocation('');
        setLocationRegionCode('');
        captureException(error);
      });
  };

  /**
   * Устанавливает данные местоположения
   * @param {Object} location
   * @param {string} location.cityName
   * @param {string} location.cityCode
   * @param {string} location.countryIsoCode
   * @param {string} location.regionCode
   * @param {string} location.fullWithType
   * @return {Promise<void>}
   */
  const setLocationData = async (location) => {
    setCityName(location?.cityName);
    setCityLocation(location?.cityCode);
    setCountryCode(location?.countryIsoCode);
    setLocationRegionCode(location?.regionCode);

    if (isRussian.value) {
      const query = location?.fullWithType || location?.cityName;
      await fetchDadataCity(query, location.cityCode);
    } else setCityCoords([]);
  };

  const setLocation = async (location, withoutRenderNuxt = false) => {
    const city = location.attributes || location.relationships?.location;

    await setLocationData(city);

    if (!withoutRenderNuxt) {
      dispatch('main/forceRenderNuxt');
    }
  };

  const initLocation = async () => {
    const defaultData = {
      cityName: MAIN_REGIONS.msk.name,
      cityCode: MAIN_REGIONS.msk.code,
      countryIsoCode: ISO_CODE_RUSSIA,
      regionCode: MAIN_REGIONS.msk.regionFias,
    };
    if (!state.cityLocation && !state.locationRegionCode) {
      await $httpClient.location.getUserLocation()
        .then(async (res) => {
          await setLocationData(res);
        })
        .catch(async () => {
          await setLocationData(defaultData);
        });
    }
  };

  const isTheSameAddress = async () => {
    if (state.closeLocation?.cityName) {
      const { cityCode: cityLocation } = state.closeLocation;
      return state.cityLocation === cityLocation;
    }
    return false;
  };

  const compareLocation = () => {
    const acceptValue = $cookies.get('BITRIX_SM_location_accept');
    if (!acceptValue || acceptValue !== 'Y' || !isTheSameAddress()) {
      state.isLocationPopup = true;
    }
  };

  const getPopularList = async () => {
    state.loading = true;

    try {
      const { list: formattedLocations } = await $httpClient.location.getPopularLocations();
      state.popularList = [];
      const importantLocations = [MAIN_REGIONS.msk.code, MAIN_REGIONS.spb.code];
      await formattedLocations.forEach((location) => {
        if (location.level === 'city') {
          location.important = importantLocations.includes(
            location.relationships.location.cityCode,
          );
          state.popularList.push(location);
        }
      });
    } catch (e) {
      state.popularList = [];
    } finally {
      state.loading = false;
    }
  };

  const handleSetLocation = async (location) => {
    await setLocation(location);
    modal.hide('CityModal');
  };

  const openCityModal = () => {
    modal.show({
      component: () => import('@/components/AppModal/index.vue'),
      bind: {
        nameModal: 'CityModal',
      },
      slots: {
        title: 'Ваш город',
        default: {
          component: () => import('@/components/_mobile/ChooseRegion/ChoiceRegionM.vue'),
          bind: {
            cityName: state.cityName,
          },
          on: {
            'set-location': location => handleSetLocation(location),
          },
        },
      },
    });
  };

  watch(
    () => state.cityName,
    async (value) => {
      if (value) state.cityName = removeExtraSymbol(value);
    },
    { immediate: true },
  );

  watch(
    () => isMoscowRegion.value,
    (value) => {
      // устанавливаем номер телефона колл центра
      dispatch('main/setPhone', value ? MAIN_REGIONS.msk.code : '');
    },
    { immediate: false },
  );

  return {
    ...toRefs(state),
    optionsSetCookie: getOptionsSetCookie($config.domainCookie),
    setLocation,
    initLocation,
    removeExtraSymbol,
    getPopularList,
    isTheSameAddress,
    compareLocation,
    isRussian,
    isUkraine,
    isMoscow,
    isMoscowRegion,
    isSpb,
    isSpbRegion,
    isSpbOrMoscow,
    openCityModal,
  };
});

const useLocationContext = () => {
  const locationStore = useLocationStore();

  if (!locationStore) {
    throw new Error('Пожалуйста, вызовите `useLocationProvider` для соответствующего родительского компонента.');
  }

  return locationStore;
};

export { useLocationProvider, useLocationContext };
