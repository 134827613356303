// @ts-check
/// <reference path="./typedefs.js" />

import PureHttpClient from '@/utils/clients/pureClient';

import { formattedElement } from './formatted';

export default class SuggestionsService {
  constructor() {
    this.mainHttp = new PureHttpClient({ baseURL: 'https://dadata.book24.ru/suggestions/api/4_1/rs' });
  }

  /**
   * Запрос к дадате
   * @param {string} query - Строка поиска
   * @param {Object} options - Опции с которыми нужно делать запрос
   * @returns {Promise<any>}
   */
  httpRequest(query, options) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/suggest/address', {
        query,
        ...options,
      })
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  /**
   * Получение подсказок
   * @param {string} query - поисковая строка
   * @param {Object} [options] - настройки
   * @param {number} [options.count=6] - количество строк в ответе
   * @param {string} [options.boundFrom='street'] - откуда искать (улица)
   * @param {string} [options.boundTo='house'] - до куда искать (дом)
   * @param {Array} [options.locations=[]] - в пределах чего искать
   * @returns {Promise<unknown>}
   */
  getSuggest(query, options) {
    return new Promise((resolve, reject) => {
      if (typeof query !== 'string' || !query.length) throw new Error('Не передано значение');
      const isOptionsEmpty = Object.entries(options).length === 0;

      const {
        count = 6,
        boundFrom = 'street',
        boundTo = 'house',
        locations = [],
      } = options;

      const reqOptions = isOptionsEmpty
        ? { count: 1 }
        : {
          count,
          restrict_value: true,
          from_bound: { value: boundFrom },
          to_bound: { value: boundTo },
          locations,
        };

      this.httpRequest(query, reqOptions)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  /**
   * Получение гранулированной подсказки
   * @param {string} query
   * @param {Object} [options]
   * @param {Array<string>} [options.bounds=['street', 'house']]
   * @param {Array} [options.locations=[]] - в пределах чего искать
   * @param {number} [options.count=6] - количество строк в ответе
   * @returns {Promise<unknown>}
   */
  getGranular(query, options = {}) {
    return new Promise((resolve, reject) => {
      if (typeof query !== 'string' || !query.length) throw new Error('Не передано значение');

      const {
        count = 6,
        bounds = ['street', 'house'],
        locations = [],
      } = options;

      // eslint-disable-next-line prefer-const
      let [boundFrom, boundTo] = bounds;
      if (!boundTo) {
        boundTo = boundFrom;
      }

      this.getSuggest(query, {
        count,
        boundFrom,
        boundTo,
        locations,
      })
        .then(({ data: { suggestions } }) => {
          const listWithoutMetro = suggestions.filter(item => item.data.street_type_full !== 'метро');

          const resultList = listWithoutMetro.map((requestData) => {
            const { value, data } = requestData;
            const unrestrictedValue = requestData.unrestricted_value;

            let valueStreet = null;
            let valueHouse = null;

            // собираем строку улицы
            if (!!data.street_with_type || !!data.settlement_with_type) {
              valueStreet = [];
              if (data.settlement_fias_id) {
                valueStreet.push(data.settlement_with_type);
              }

              valueStreet.push(data.street_with_type);
              valueStreet = valueStreet.filter(part => part).join(', ');
            }

            const hasHouse = data.house && data.house_type;
            const hasBlock = data.block && data.block_type;

            if (hasHouse && hasBlock) {
              valueHouse = `${data.house_type} ${data.house}, ${data.block_type} ${data.block}`;
            } else if (hasHouse && !hasBlock) {
              valueHouse = `${data.house_type} ${data.house}`;
            } else if (!hasHouse && hasBlock) {
              valueHouse = `${data.block_type} ${data.block}`;
            } else if (!data.block || !data.house) {
              const val = value.split(', ');
              valueHouse = data.street ? [val[val.length - 1]].filter(part => part).join(', ') : value;
            }

            return {
              value,
              valueStreet,
              valueHouse,
              unrestrictedValue,
              data: formattedElement(data, unrestrictedValue),
            };
          });

          resolve(resultList);
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Получение полной подсказки
   * @param {string} query
   * @param {Object} [options]
   * @param {Array<string>} [options.bounds=['street', 'house']]
   * @param {Array<Object>} [options.locations=[]]
   * @param {number} [options.count=6]
   * @returns {Promise<unknown>}
   */
  getFullSuggest(query, options) {
    return new Promise((resolve, reject) => {
      if (typeof query !== 'string' || !query.length) throw new Error('Не передано значение');

      const {
        count = 6,
        bounds = ['street', 'house'],
        locations = [],
      } = options;

      const [boundFrom, boundTo] = bounds;

      const optionsReq = options.count !== 1
        ? {
          count,
          boundFrom,
          boundTo,
          locations,
        } : {};

      this.getSuggest(query, optionsReq)
        .then(({ data: { suggestions } }) => {
          const resultList = suggestions.map(({ value, data, unrestricted_value: fullValue }) => ({
            value,
            data: formattedElement(data, fullValue),
          }));
          resolve(resultList);
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Получение подсказки о городе
   * @param {PayloadGetInfoAboutCity} params
   * @returns {Promise<ItemAboutCity>}
   */
  getInfoAboutCity({ query, location }) {
    return new Promise((resolve, reject) => {
      const dataRes = {
        query,
        count: 1,
      };

      if (location) {
        dataRes.locations = [
          { settlement_fias_id: location },
          { city_fias_id: location },
        ];
      }

      this.mainHttp.post('/suggest/address', dataRes)
        .then(({ data: { suggestions } }) => {
          const [item] = suggestions;
          const info = {
            coords: [],
            regionName: null,
            regionCode: null,
            cityCode: null,
            cityName: null,
            index: null,
          };

          if (item) {
            info.coords = [item.data.geo_lat, item.data.geo_lon];
            info.regionName = item.data.region_with_type;
            info.regionCode = item.data.region_fias_id;
            info.cityCode = item.data.fias_id;
            info.cityName = item.data.settlement && item.data.settlement.length > 0
              ? item.data.settlement : item.data.city;
            info.index = item.data.postal_code;
          }
          resolve(info);
        })
        .catch(error => reject(error));
    });
  }
}
